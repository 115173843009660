.Base {
  width: 100%;
  height: 80px;
  background-color: black;
  display: none;
}

.NavBar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 80px;
  background-color: black;
  z-index: 99;
  opacity: 0.8;
}

.Container {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.LogoImg {
  padding-left: 5%;
  height: 15vh;
  opacity: 1.0;
}

.ButtonContainer {
  padding: 0px 48px;
  display: flex;
  flex-direction: row;
}

.Button {
  cursor: pointer;
  border: none;
  background: transparent;
  color: #CCB181;
  padding: 5px 5px;
  font-family: "Roboto-Thin";
  font-size: 16px;
  font-weight: bold;
}

.Seprate {
  background: transparent;
  color: #CCB181;
  padding: 5px 5px;
  font-family: "Roboto-Thin";
  font-size: 16px;
  font-weight: bold;
  align-self: center;
}

.LanguageButton {
  cursor: pointer;
  height: 42px;
  width: 42px;
  border-color: white;
  border: 0.5px solid;
  background: transparent;
  color: white;
  margin-left: 10px;
  font-family: "Roboto-Thin";
  font-size: 20px;
  text-align: center;
}

.MenuButton {
  height: 45px;
  padding-right: 24px;
  display: none;
}

.Menu {
  display: none;
  position: fixed;
  width: 100%;
  height: 100vh;
  background-color: black;
  z-index: 5;
  justify-content: center;
  align-items: center;
  z-index: 100;
}

.MenuButtonContainer {
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 950px) {
  .Base {
    display: flex;
  }

  .NavBar {
    height: 80px;
  }

  .Container {
    height: 80px;
  }

  .ButtonContainer {
    display: none;
  }

  .Menu {
    display: flex;
  }

  .MenuButton {
    display: block;
  }

  .Button {
    padding: 10px 0px 10px 0px;
  }

  .LanguageButton {
    margin: auto;
    margin-top: 10px;
    font-size: 16px;
  }

  .Seprate {
    display: none;
  }
}
