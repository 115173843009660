.Main {
  position: relative;
  background-color: black;
  width: 100%;
}

.Page {
  position: absolute;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.Img {
  width: 100%;
}

.InnerPage {
  width: 90%;
  display: flex;
  align-items: center;
}

.ShowFisrtArea {
  width: 50%;
}

.ShowAboutArea {
  width: 50%;
  align-self: flex-start;
}

.FirstImg {
  width: 100%;
  max-height: 100%;
}

.TitleImg {
  width: 80%;
}

.AboutusText {
  color: white;
  text-align: left;
  line-height: 3vw;
  font-size: 2vw;
  padding-left: 10%;
  padding-right: 10%;
  
  en-US
  {
    line-height: 1.5vw;
    font-size: 1.5vw;
  }

  zh-TW
  {
    line-height: 3vw;
    font-size: 2vw;
  }
}

@media screen and (max-width: 1024px) {
  .InnerPage {
    width: 96%;
    display: flex;
    align-items: center;
  }

  .AboutusText {
    padding-left: 5%;
    padding-right: 0%;
  }
}

