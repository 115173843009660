.Main {
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: black;
    width: 100%;
    align-items: center;
    padding-top: 40px;
    padding-bottom: 40px;
}

.Links {
    display: flex;
    padding-top: 20px;
    padding-bottom: 20px;
    width: 40%;
    max-width: 450px;
    min-width: 300px;    
    justify-content: space-between;
}

.CopyRight {
    color: #B2936F;
}