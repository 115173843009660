
.Main {
    background-color: black;
    position: relative;
    width: 100%;
}

.ShinyLeft {
    position: absolute;
    width: 33%;
    left: -5%;
    top: -5%;
}

.ShinyRight {
    position: absolute;
    width: 33%;
    right: 0%;
    bottom: 18%;
}

.ShinyImg {
    width: 100%;
}

.Title {
    padding : 30px;
    width: 50%;
    z-index: 10;
}

.WorkMoml {
    display: grid;
    padding-left: 10%;
    padding-right: 10%;
    align-items: center;
    justify-content: center;
    grid-template-columns: 769fr 359fr;
    row-gap: 0;
    z-index: 5;
}

.MomlHyper {
    z-index: 5;
}

.MomlImg {
    width: 100%;
    z-index: 5;
}

.MomlTextArea {
    display: flex;
    height: 100%;
    padding-left: 20px;
    padding-right: 20px;
    background-color: #DDDBB5;
    align-items: center;
    text-align: left;
    font-size: 2vw;
    line-height: 3vw;
    z-index: 5;

    en-US
    {
      line-height: 2vw;
      font-size: 1.5vw;
    }
  
    zh-TW
    {
      font-size: 2vw;
      line-height: 3vw;
    }
}

.Works {
    display: grid;
    padding-top: 20px;
    padding-left: 10%;
    padding-right: 10%;
    justify-content: space-between;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap: 20px;
    grid-template-areas: 
    "eggplant seekertext mom"
    "eggplanttext seeker momtext"
}

.WorksImg {
    width: 100%;
    object-fit: contain;
    z-index: 5;
}

.WorksText {
    padding: 25px;
    object-fit: contain;
    z-index: 5;
}

.Eggplant {
    grid-area: eggplant;
    z-index: 5;
}

.EggplantText {
    width: 100%;
    grid-area: eggplanttext;
    display: flex;
    background-color: #BCC277;
    align-items: center;
    text-align: left;
    z-index: 5;
    font-size: 1.5vw;
}

.Seeker {
    grid-area: seeker;
    z-index: 5;
}

.SeekerText {
    width: 100%;
    grid-area: seekertext;
    display: flex;
    background-color: #A298A4;
    align-items: center;
    text-align: left;
    z-index: 5;
    font-size: 1.5vw;
}

.Mom {
    grid-area: mom;
    z-index: 5;
}

.MomText {
    width: 100%;
    grid-area: momtext;
    display: flex;
    background-color: #E2E2DA;
    align-items: center;
    text-align: left;
    z-index: 5;
    font-size: 1.5vw;
}

.AvatarArea {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.AvatarImg {
    width: 33%;
}

@media screen and (max-width: 950px) {
    .Works {
        display: grid;
        padding-top: 20px;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(3, 1fr);
        gap: 15px;
        grid-template-areas: 
        "mom momtext"
        "seeker seekertext"
        "eggplant eggplanttext"
    }

    .EggplantText {
        font-size: 2vw;
    }

    .SeekerText {
        font-size: 2vw;
    }

    .MomText {
        font-size: 2vw;
    }
}
