.Main {
    position: relative;
    background-color: black;
    width: 100%;
}

.TitleArea {
    display: grid;
    width: 100%;
    grid-template-columns: 963fr 477fr;
    grid-template-areas: "zero title";
}

.Zero {
    grid-area: zero;
    width: 100%;
}

.Title {
    display: flex;
    grid-area: title;
    justify-items: center;
    align-items: center;
}

.TitleImg {
    width: 100%;
}

.GalleryGrid {
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 20px;
}