.Main {
    position: relative;
    background-color: black;
    width: 100%;
  }

.Img {
    width: 100%;
  }

  .Page {
    position: absolute;
    top: 0%;
    left: 0%;
    width: 100%;
    display: flex;
    justify-content: center;
  }
    
  .InnerPage {
    width: 100%;
    justify-content: center;
    align-content: flex-start;
  }

  .TitleImg {
      padding-top: 20px;
      padding-bottom: 5px;
      width: 50%;
      z-Index: 5
  }

  .PartnerArea {
      display: flex;
      padding-left: 10%;
      padding-right: 10%;
      padding-top: 5%;
  }

  .PartnerGrid {
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 20px;
    align-self: center;
}

.PartnerGridItem {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.PartnerImg {
    width: 70%;
}

.PartnerFont {
    font-size: 2vw;
    font-weight: bold;
}

.CorpFont {
    font-size: 1vw;
}

@media screen and (max-width: 950px) {
    .PartnerGrid {
        gap: 5px;
    }

    .PartnerFont {
        font-size: 1.6vw;
    }
    
    .CorpFont {
        font-size: 1vw;
    }
}

@media screen and (max-width: 650px) {
    .PartnerArea {
        padding-top: 2%;
    }

    .PartnerGrid {
        gap: 0px;
    }
}
