.App {
  text-align: center;
  max-width: 100%;
}

.BackgroundImg {
  position: fixed;
  object-fit: contain;
  min-width: 100%;
  min-height: 100%;
  bottom: 0;
  right: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
}
